import React from 'react'
import fileToDownload from '../../assets/APK/MyPlayLogBETA.apk' // Chemin relatif du fichier à télécharger
import styled from 'styled-components'
const Button = styled.button`
  transition-duration: 0.1s;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`
const DownloadApk = () => {
  const handleDownload = () => {
    // Créer un élément d'ancrage invisible pour le téléchargement
    const anchor = document.createElement('a')
    anchor.href = fileToDownload
    anchor.download = 'MyPlayLog.apk' // Nom du fichier téléchargé

    // Ajouter l'élément d'ancrage au DOM et déclencher le téléchargement
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }

  return (
    <Button onClick={handleDownload} className="bg-transparent border-none ">
      {' '}
      <img
        src={require('../../assets/googlePlay.webp')}
        alt="download-apk"
        className="w-36 "
      />{' '}
    </Button>
  )
}

export default DownloadApk
