import React, { useState, useEffect, useContext } from 'react'
import { Switch } from 'antd'
import colors from '../../../../utils/style/colors'
import { LoginData } from '../../../../Helper/Context'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function SectionPrivateAccount() {
  const { loginData, setLoginData } = useContext(LoginData)
  const [isPrivate, setIsPrivate] = useState(loginData.private)

  useEffect(() => {
    if (loginData) {
      setIsPrivate(loginData.private)
    }
  }, [loginData])

  const togglePrivate = async () => {
    const updatedPrivate = !isPrivate

    try {
      const config = {
        method: 'put',
        url: `${process.env.REACT_APP_IP_ADRESS}/api/user/private`,
        withCredentials: true,
      }

      await axios(config)

      // Mettre à jour l'état local après la requête réussie
      setIsPrivate(updatedPrivate)

      setLoginData((prevLoginData) => ({
        ...prevLoginData,
        private: updatedPrivate,
      }))

      toast.success(
        `Profil ${
          updatedPrivate ? 'passé en privé' : 'devenu publique'
        } avec succès`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        }
      )
    } catch (error) {
      console.error(error)
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
      })
    }
  }

  return (
    <div
      className="flex flex-col gap-5 p-6 rounded-lg shadow-xl overflow-hidden"
      style={{ background: colors.backgroundDerivation }}
    >
      <div className="flex flex-col gap-5">
        {/* <h2>Privacy</h2> */}
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-lg">Rendre mon profil privé :</label>
          <Switch checked={isPrivate} onChange={togglePrivate} />
        </div>
      </div>
    </div>
  )
}
