import { Modal } from 'antd'
import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Checkbox } from 'antd'
import LoadingBar from 'react-top-loading-bar'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import { RiEmotionSadLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'

import { Loader } from '../../../../utils/Atoms'
import { LoginData } from '../../../../Helper/Context'
import colors from '../../../../utils/style/colors'
import './styles.scss'
import { FaSearch } from 'react-icons/fa'
import ImportSteamGames from './ImportSteamGames'
const CheckBtn = styled.button`
  background-color: ${(props) =>
    props.valide ? '#ea442b' : 'rgba(217, 217, 217, 0.22)'};
  display: flex;
  overflow: hidden;
  gap: 2px;
  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  border: 0 none;
  font-size: 5px;
  font-weight: 500;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 2px 5px 10px black;

  &:hover {
    transition: all 150ms linear;
    opacity: 0.85;
  }
  &:active {
    transition: all 150ms linear;
    opacity: 0.75;
  }

  border-radius: 666px;
`

const ConfirmBtn = styled.button`
  background-color: ${colors.favorable};
  overflow: hidden;
  gap: 2px;
  padding: 4px 14px;
  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  color: ${colors.backgroundDerivation};
  border: 0 none;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 10px black;
  flex: 0 0 160px;
  &:hover {
    transition: all 150ms linear;
    opacity: 0.85;
  }
  &:active {
    transition: all 150ms linear;
    opacity: 0.75;
  }

  border-radius: 666px;
`
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: 'white',
    }}
    spin
  />
)
export default function SteamLinkingModal({ isModalOpen, setIsModalOpen }) {
  const { loginData, setLoginData } = useContext(LoginData)
  const [userResult, setUserResult] = useState(null)
  const [noResult, setNoResult] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [steamId, setSteamId] = useState('')
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const checkSteamAccount = (e) => {
    e.preventDefault()
    if (steamId !== '') {
      setIsLoading(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_IP_ADRESS}/api/steam/check/${steamId}`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
      axios(config)
        .then((res) => {
          console.log(res.data)
          if (res.data.message) {
            setNoResult(true)
            setUserResult(null)
          } else {
            setUserResult(res.data)
            setNoResult(false)
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        })
        .finally(() => setIsLoading(false))
    }
  }

  const handleConfirmAccount = () => {
    if (userResult) {
      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_IP_ADRESS}/api/steam/sync/`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: userResult,
      }
      axios(config)
        .then((res) => {
          console.log(res.data)
          setLoginData(res.data.newUser)
          setSuccess(true)
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        })
    }
  }
  return (
    <Modal
      title={
        loginData.steamAccount && loginData.steamAccount.steamId
          ? 'Importing your steam games'
          : 'Link your steam account'
      }
      open={isModalOpen}
      className="steamModal"
      onCancel={handleCancel}
      style={{ minWidth: '300px' }}
      footer={[]}
    >
      {loginData.steamAccount && loginData.steamAccount.steamId ? (
        <ImportSteamGames />
      ) : (
        <div className="flex flex-col gap-2">
          <label>Your steam Id</label>
          <form onSubmit={checkSteamAccount} className="flex flex-row gap-2 ">
            <input
              onSubmit={checkSteamAccount}
              value={steamId}
              onChange={(e) => {
                setSteamId(e.target.value)
                if (noResult) setNoResult(false)
              }}
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
              className="flex-1 p-2  border-none text-white text-lg"
              type="number"
              placeholder="Steam Id"
            />
            <CheckBtn onClick={checkSteamAccount}>
              {isLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                <FaSearch size={25} />
              )}
            </CheckBtn>
          </form>
          {!userResult && (
            <p className="text-sm text-slate-400">
              Steam ID is a unique identifier associated with your Steam
              account, distinct from your Steam username. If you need to find
              your Steam ID, you can visit{' '}
              <a
                style={{ color: colors.primary }}
                href="https://www.steamidfinder.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                this link
              </a>{' '}
              for assistance.
            </p>
          )}

          {userResult && (
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col ">
                  <img
                    alt={`profile pic of ${userResult.personaname}`}
                    src={userResult.avatarfull}
                    className="h-24 w-24 object-cover"
                  />
                  <h2>{userResult.personaname}</h2>
                </div>
              </div>

              <div>
                <ConfirmBtn
                  onClick={handleConfirmAccount}
                  className="w-fit h-fit"
                >
                  Yes, it's me !
                </ConfirmBtn>
              </div>
            </div>
          )}
          {noResult && (
            <div className="flex flex-col gap-1">
              <div className=" flex flex-row justify-center gap-2 items-center">
                {' '}
                <h2>No result</h2> <RiEmotionSadLine size={35} />
              </div>
              <h4>
                Are you sure it's your correct Steam ID and not your username ?
                To find your Steam ID you can check{' '}
                <a
                  style={{ color: colors.primary }}
                  href="https://www.steamidfinder.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this link
                </a>
                . Also, make sure that your profile is public and not private.
              </h4>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
}
