import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { initializeSocket, getSocket } from '../../socket'
import { text } from '@fortawesome/fontawesome-svg-core'
import { LoginData } from '../../Helper/Context'
import { useContext } from 'react'

const handlePing = () => {
  axios
    .get(`${process.env.REACT_APP_IP_ADRESS}/ping`)
    .then((res) => console.log(res.data))
}
const TestSocket = ({ isSocketConnected }) => {
  const { loginData } = useContext(LoginData)

  const [msgs, setMsgs] = useState([])
  const [textValue, setTextValue] = useState('')
  const handleOnSubmit = (e) => {
    const socket = getSocket() // Obtenir le socket

    e.preventDefault()
    if (textValue !== '') {
      socket.emit('chat message', textValue)
      setTextValue('')
    }
  }

  useEffect(() => {
    if (isSocketConnected) {
      const socket = getSocket() // Obtenir le socket

      socket.on('chat message', function (msg) {
        console.log(msg)
        setMsgs((prevMsg) => [...prevMsg, msg])
      })

      // Nettoyage du socket à la fin
      return () => {
        socket.disconnect()
      }
    }
  }, [isSocketConnected])

  return (
    <div
      style={{ minHeight: '100vh' }}
      className="flex justify-center items-center m-auto flex-col flex-1  h-screen h-full pt-24 h-full"
    >
      Test Socket.IO
      <ul>
        {msgs.map((msg, index) => {
          return <li key={`${msg}_${index}`}>{msg}</li>
        })}
      </ul>
      <form onSubmit={handleOnSubmit}>
        <input
          type="text"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          onSubmit={handleOnSubmit}
        />
        <button type="submit">SEND</button>
      </form>
      <button onClick={handlePing}>PING</button>
    </div>
  )
}

export default TestSocket
