import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Button, Popconfirm } from 'antd'
import { BsPlaystation, BsSteam } from 'react-icons/bs'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { toast } from 'react-toastify'
import { DeleteOutlined } from '@ant-design/icons'

import './styles.css'
import colors from '../../../utils/style/colors'
import { LoginData } from '../../../Helper/Context'
import SteamLinkingModal from './SteamLinkingModal/SteamLinkingModal'
import PsnLinkingModal from './PsnLinkingModal/PsnLinkingModal'
import i18next, { t } from 'i18next'

const Section = styled.section`
  width: fit-content;
  display: block;
  margin-bottom: 2rem;
`
const SectionHeader = styled.div`
  padding: 0 2em 0 0;
  grid-column: 1;
  display: flex;
  justify-content: center;
`
const SectionTitle = styled.h3`
  margin-bottom: 5px;
  font-size: 1.4rem;
  color: white;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    left: -10px;
    top: 0;
    bottom: 0;
    border-radius: 0 3px 3px 0;
    background: ${colors.primary};
    z-index: 1;
  }
`

export default function LinkedAccountsTab({ setProgress }) {
  const { loginData, setLoginData } = useContext(LoginData)
  const [isSteamModalOpen, setIsSteamModalOpen] = useState(false)
  const [isPsnModalOpen, setIsPsnModalOpen] = useState(false)

  const handleUnlinkSteamAccount = () => {
    setProgress(10)
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_IP_ADRESS}/api/steam/unlink/`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    axios(config)
      .then((res) => {
        setProgress(50)
        console.log(res.data)
        setLoginData((prevLoginData) => ({
          ...prevLoginData,
          steamAccount: null,
        }))
        toast.success('Your steam account was unlinked with success', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        })
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        })
      })
      .finally(() => setProgress(100))
  }

  const handleUnlinkPsn = () => {
    setProgress(10)
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_IP_ADRESS}/api/psn/unlink/`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    axios(config)
      .then((res) => {
        if (res.data.message === 'PSN account unlinked successfully') {
          setLoginData((prevLoginData) => ({
            ...prevLoginData,
            psnAccount: null,
          }))
          toast.success('PSN account unlinked successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        })
      })
      .finally(() => setProgress(100))
  }

  return (
    <>
      <SteamLinkingModal
        isModalOpen={isSteamModalOpen}
        setIsModalOpen={setIsSteamModalOpen}
      />
      <PsnLinkingModal
        isModalOpen={isPsnModalOpen}
        setIsModalOpen={setIsPsnModalOpen}
      />
      <div className="flex flex-col  justify-center items-center gap-2 w-4/5">
        <Section>
          <SectionHeader>
            <SectionTitle>Linking your accounts</SectionTitle>
          </SectionHeader>
          <p className="  text-slate-200 text-base font-medium leading-5 ">
            Link your accounts to complete your game library in a matter of
            seconds and import your playtime, achievements, and more. It's
            important to note that your password is not mandatory, although you
            must have your profile set to public if it isn't already.
          </p>
        </Section>
        {loginData.steamAccount && loginData.steamAccount.steamId ? (
          <Section>
            <SectionHeader>
              {' '}
              <SectionTitle>Your Steam Account</SectionTitle>
            </SectionHeader>
            <div className="flex flex-col items-center justify-center ">
              <img
                alt={`profile pic of ${loginData.steamAccount.steamUsername}`}
                src={loginData.steamAccount.steamPic}
                className="h-24 w-24 object-cover"
              />
              <h2>{loginData.steamAccount.steamUsername}</h2>
              <h3>
                Imported{' '}
                <span style={{ color: colors.primary }}>
                  {loginData.games || loginData.pendingGames
                    ? (loginData.games &&
                        loginData.games.filter((game) => game.steamAppId)
                          .length) +
                      (loginData.pendingGames &&
                        loginData.pendingGames.filter((game) => game.steamAppId)
                          .length)
                    : 0}
                </span>{' '}
                games.{' '}
              </h3>{' '}
              {loginData.steamAccount.lastSync && (
                <span className="text-sm font-light mb-1">
                  (Last Import{' '}
                  {new Intl.DateTimeFormat(i18next.language).format(
                    new Date(loginData.steamAccount.lastSync)
                  )}
                  )
                </span>
              )}
              <Popconfirm
                title="Unlink your Steam Account"
                description={() => (
                  <p className="text-sm ">
                    Are you sure to unlink your account ? <br />
                    Your imported games won't be lost.
                  </p>
                )}
                okText={t('yes')}
                cancelText={t('no')}
                placement="bottom"
                onConfirm={handleUnlinkSteamAccount}
              >
                <button className=" p-2 px-4 gap-2 text-white justify-center rounded-md items-center flex flex-row  border-none bg-black hover:bg-red-600 cursor-pointer">
                  {' '}
                  <div className="flex justify-center items-center h-full">
                    <MdOutlineDeleteSweep size={20} />
                  </div>
                  <span className=" text-xs">UNLINK MY STEAM ACCOUNT</span>
                </button>
              </Popconfirm>
            </div>
          </Section>
        ) : (
          <>
            <Section>
              <button
                className="steambutton"
                onClick={() => setIsSteamModalOpen(true)}
              >
                <span>Link your Steam</span>
                <div className="steam-icon">
                  <div className="flex justify-center items-center h-full">
                    <BsSteam size={35} />
                  </div>
                </div>
              </button>
            </Section>
          </>
        )}

        <Section>
          {loginData.psnAccount && loginData.psnAccount.psnId ? (
            <div
              className="w-fit p-2 rounded-md  flex flex-row items-center justify-between"
              style={{ backgroundColor: '#0070D1' }}
            >
              <div className="flex flex-row items-center justify-center">
                {' '}
                <img
                  src={loginData.psnAccount.avatarUrl}
                  alt="profilePic"
                  className="w-11 rounded-full shadow-black/30 shadow-xl"
                />
                <span className="ml-2 text-base font-medium">
                  {loginData.psnAccount.username}
                </span>
              </div>
              <button
                className="ml-5 bg-white/50 hover:bg-white/90 border-hidden p-1 rounded-md cursor-pointer pb-0 transition-colors duration-300 shadow-black/30 shadow-xl"
                onClick={handleUnlinkPsn}
              >
                <DeleteOutlined className="text-lg" />
              </button>
            </div>
          ) : (
            <button
              className="steambutton psnbutton"
              onClick={() => setIsPsnModalOpen(true)}
            >
              <span>Link your PSN</span>
              <div className="steam-icon">
                <div className="flex justify-center items-center h-full">
                  <BsPlaystation size={35} />
                </div>
              </div>
            </button>
          )}
        </Section>
      </div>
    </>
  )
}
