import colors from '../../utils/style/colors'
import { Link } from 'react-router-dom'

import './style.scss'
import { t } from 'i18next'

const UserCard = ({ ele }) => {
  return (
    <Link to={`/user/${ele._id}`}>
      <div className="user-card-container shadow-lg hover:shadow-2xl mr-2">
        {!ele.coverPic ? (
          <div
            className="svg-background"
            style={{
              backgroundImage:
                'linear-gradient(to right top, #ffc500, #fec900, #fecd00, #fdd100, #fcd500)',
            }}
          />
        ) : (
          <div
            className="svg-background"
            style={{
              background: `url(${ele.coverPic})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          />
        )}

        <div className="svg-background2"></div>
        <div className="circle"></div>
        {/* <img
        className="menu-icon"
        src="https://pngimage.net/wp-content/uploads/2018/06/white-menu-icon-png-8.png"
        alt="menu icon"
      /> */}
        <img className="profile-img" src={ele.profilePic} alt="Profile" />
        <div className="text-container">
          <p className="title-text">{ele.username}</p>
          <p className="info-text text-sm break-all overflow-hidden ">
            <span>
              <strong style={{ color: colors.primary }}>
                {ele.followers?.length || 0}{' '}
              </strong>{' '}
              {t('followers')}{' '}
            </span>
            |
            <span>
              <strong style={{ color: colors.primary }}>
                {' '}
                {ele.following?.length || 0}{' '}
              </strong>
              {t('following')}
            </span>
          </p>
          <p className="desc-text text-slate-300">{ele.bio} </p>
        </div>
      </div>
    </Link>
  )
}

export default UserCard
