import React, { useState, useEffect } from 'react'
import ToggleBear from './ToggleBear'
import { Switch } from 'antd'
import colors from '../../../utils/style/colors'

export default function NotificationsTab() {
  const [isSoundEnabled, setIsSoundEnabled] = useState(true)

  useEffect(() => {
    // Récupérer la valeur actuelle du son des notifications depuis le localStorage
    const soundEnabled = localStorage.getItem('soundEnabled')
    setIsSoundEnabled(soundEnabled === 'true')
  }, [])

  const toggleSound = () => {
    // Inverser l'état du son et mettre à jour le localStorage
    const updatedSoundEnabled = !isSoundEnabled
    setIsSoundEnabled(updatedSoundEnabled)
    localStorage.setItem('soundEnabled', updatedSoundEnabled.toString())
  }

  return (
    <div
      className="flex flex-col gap-5 p-6 rounded-lg shadow-xl overflow-hidden"
      style={{ background: colors.backgroundDerivation }}
    >
      <div className="flex flex-col gap-5">
        <h2>Notifications</h2>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className=" text-lg">Activer le son des notifications:</label>
          <Switch defaultChecked={isSoundEnabled} onChange={toggleSound} />
        </div>
      </div>
    </div>
  )
}
