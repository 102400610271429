import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { AiOutlineMinus } from 'react-icons/ai'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import axios from 'axios'

import MyPlayLogIcon from '../../../assets/LOGO_placeholder.png'
import colors from '../../../utils/style/colors'
import { LoginData } from '../../../Helper/Context'
import PendingGamesCard from './PendingGameCard'

const GamesContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

const BtnSelectAll = styled.button`
  background-color: ${(props) =>
    props.selected ? colors.primary : colors.background};
  display: flex;
  overflow: hidden;
  gap: 2px;
  padding: 8px 0px;
  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  border: 0 none;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  flex: 0 0 160px;
  box-shadow: 2px 5px 10px black;

  &:hover {
    transition: all 150ms linear;
    opacity: 0.85;
  }
  &:active {
    transition: all 150ms linear;
    opacity: 0.75;
  }

  border-radius: 666px;
`

export default function PendingGamesTab() {
  const { loginData, setLoginData } = useContext(LoginData)
  const { t } = useTranslation()

  const [pendingGames, setPendingGames] = useState(
    loginData.pendingGames
      ? loginData.pendingGames
          .filter((game) => !game.ignored)
          .sort((a, b) => a.name.localeCompare(b.name))
      : []
  )

  const [ignoredGames, setIgnoredGames] = useState(
    loginData.pendingGames
      ? loginData.pendingGames.filter((game) => game.ignored)
      : []
  )

  const [showIgnored, setShowIgnored] = useState(false)
  const handleCheckGame = (id_IGDB) => {
    setPendingGames((prev) => {
      return prev.map((prevGame) => {
        if (prevGame.id_IGDB === id_IGDB) {
          return {
            ...prevGame,
            selected: prevGame.selected ? false : true,
          }
        } else return prevGame
      })
    })
  }
  const handleCheckAll = () => {
    let check = true
    if (
      pendingGames.filter((game) => game.selected).length ===
      pendingGames.length
    )
      check = false
    setPendingGames((prevPending) =>
      prevPending.map((game) => ({
        ...game,
        selected: check,
      }))
    )
  }

  const handleChangeStatus = (status, id_IGDB) => {
    setPendingGames((prev) => {
      return prev.map((prevGame) => {
        if (prevGame.id_IGDB === id_IGDB) {
          return {
            ...prevGame,
            status: status,
          }
        } else return prevGame
      })
    })
  }

  const handleIgnoreGame = (gameToIgnore) => {
    setIgnoredGames((prev) => [...prev, { ...gameToIgnore }])
    setPendingGames((prev) => {
      return prev.filter((prevGame) => {
        return prevGame.id_IGDB !== gameToIgnore.id_IGDB
      })
    })
    const config = {
      method: 'put',
      withCredentials: true,
      url: `${process.env.REACT_APP_IP_ADRESS}/api/user/pending/ignore/${gameToIgnore.id_IGDB}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {},
    }
    axios(config)
      .then((res) => {
        toast.success('Game ignored with success.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        })
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        })
      })
  }

  const handleRemoveFromIgnored = (gameToRestore) => {
    setPendingGames((prev) => [{ ...gameToRestore }, ...prev])
    setIgnoredGames((prev) => {
      return prev.filter((prevGame) => {
        return prevGame.id_IGDB !== gameToRestore.id_IGDB
      })
    })
  }

  const handleAddGamesToCollection = () => {
    const gamesToSave = pendingGames
      .filter((game) => game.selected)
      .map((game) => ({
        ...game,
        platformPlayed: game.steamAppId ? 6 : 48, //Jouer sur Steam sinon sur PS4
      }))

    console.log(gamesToSave)
    var config = {
      method: 'delete',
      withCredentials: true,
      url: `${process.env.REACT_APP_IP_ADRESS}/api/user/pending/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: gamesToSave,
    }

    axios(config)
      .then((res) => {
        setLoginData((prevLoginData) => ({
          ...prevLoginData,
          games: res.data.newGames,
          pendingGames: pendingGames.filter((game) => !game.selected),
        }))

        setPendingGames((prev) => {
          return prev.filter((game) => !game.selected)
        })

        toast.success('Game added with success', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 1000,
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
        })
      })
  }

  return (
    <>
      <div
        style={{ height: '100vh', marginTop: -80, paddingTop: 80 }}
        className="flex  relative flex-col  p-2 flex-1 overflow-y-scroll  scrollbar-thumb-yellow-500 scrollbar-thin scrollbar-rounded-[50px]"
      >
        {(pendingGames && pendingGames.length > 0) ||
        (ignoredGames && ignoredGames.length > 0) ? (
          <>
            <div className=" fixed  w-fit left-0 right-0 mx-auto justify-center flex items-center  h-fit bottom-20  lg:bottom-10 z-30 ">
              <BtnSelectAll selected={false} onClick={handleCheckAll}>
                {pendingGames.filter((game) => game.selected).length ===
                pendingGames.length
                  ? 'UNCHECK ALL'
                  : 'CHECK  ALL'}
              </BtnSelectAll>
              <BtnSelectAll
                onClick={handleAddGamesToCollection}
                selected={true}
              >
                {' '}
                <span
                  style={{
                    color: colors.backgroundDerivation,
                    fontWeight: 800,
                  }}
                >
                  ADD ALL ({pendingGames.filter((game) => game.selected).length}
                  )
                </span>
              </BtnSelectAll>
            </div>
            <GamesContainer className=" mt-2">
              <h2 className="flex flex-row gap-1 items-start">
                <span style={{ color: colors.primary }}>
                  {pendingGames.length}
                </span>
                {'  '}
                games waiting your approvement. |{' '}
                <span style={{ color: colors.danger }}>
                  {ignoredGames.length}
                </span>{' '}
                ignored games.{' '}
                <button
                  onClick={() => setShowIgnored(!showIgnored)}
                  className=" p-1 text-xs border-none text-white bg-transparent cursor-pointer hover:underline"
                >
                  {showIgnored ? (
                    <>
                      <BsEye color="white" size={20} />
                    </>
                  ) : (
                    <>
                      <BsEyeSlash color="white" size={20} />
                    </>
                  )}
                </button>
              </h2>
              {showIgnored && (
                <>
                  <div
                    style={{ background: colors.backgroundDerivation }}
                    className="rounded-md  flex flex-col w-full  flex-wrap "
                  >
                    <div className=" flex flex-row flex-wrap w-full ">
                      {ignoredGames.map((game) => (
                        <div
                          key={`ignored_${game.id_IGDB.toString()}`}
                          className=" flex flex-row w-1/3 gap-2 p-2 overflow-hidden"
                        >
                          <img
                            className=" object-cover  w-28 "
                            src={
                              game.cover
                                ? `https://images.igdb.com/igdb/image/upload/t_cover_big/${
                                    game.cover ? game.cover : 'undefined'
                                  }.jpg`
                                : MyPlayLogIcon
                            }
                            alt={'Cover of ' + game.name}
                          />
                          <div className="flex flex-col gap-1">
                            <h5 className=" break-words whitespace-wrap text-ellipsis">
                              {game.name}
                            </h5>
                            {game.timePlayed === 0 ? (
                              'Never Played'
                            ) : (
                              <>
                                {' '}
                                Played{' '}
                                <strong style={{ color: colors.primary }}>
                                  {game.timePlayed} hours
                                </strong>
                              </>
                            )}
                            <button
                              onClick={() => handleRemoveFromIgnored(game)}
                              className="w-fit border-slate-200 cursor-pointer hover:bg-slate-200 hover:text-black font-semibold rounded-l px-2 py-1 text-xs bg-transparent text-slate-200"
                            >
                              ADD AGAIN
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      onClick={() => setShowIgnored(false)}
                      className=" border-none bg-transparent cursor-pointer "
                    >
                      <AiOutlineMinus color={colors.primary} size={30} />
                    </button>
                  </div>
                </>
              )}

              {pendingGames.map((game) => {
                return (
                  <>
                    <Divider style={{ background: 'white', margin: 5 }} />
                    <PendingGamesCard
                      handleCheckGame={handleCheckGame}
                      handleAddGamesToCollection={handleAddGamesToCollection}
                      game={game}
                      handleChangeStatus={handleChangeStatus}
                      handleIgnoreGame={handleIgnoreGame}
                    />
                  </>
                )
              })}
            </GamesContainer>
          </>
        ) : (
          <h2>No games imported yet.</h2>
        )}
      </div>
    </>
  )
}
