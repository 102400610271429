import { memo, useContext, useEffect, useRef, useState } from 'react'
import { Sidebar } from 'react-pro-sidebar'
import './style.scss'
import colors from '../../../utils/style/colors'
import { LoginData } from '../../../Helper/Context'
import styled from 'styled-components'
import axios from 'axios'
import {
  AcceptedFollowCard,
  AcceptedListCollab,
  LikeListNotifCard,
  LikeReplyNotifCard,
  LikeReviewNotifCard,
  ListCollabInvitationCard,
  NewFollowRequestCard,
  NewFollowerNotifCard,
  ReplyToMyReviewNotifCard,
  UnlockedBadge,
} from './NotifCards/NotifCards'
import { toast } from 'react-toastify'

const SectionTitle = styled.h3`
  color: ${({ color }) => color};
  font-weight: 800;
  position: relative;

  &:before,
  &:after {
    content: '';
    width: 200%;
    height: 2px;
    background-color: ${({ color }) => color};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 0;
    transform: translateX(-115%);
  }

  &:after {
    right: 0;
    transform: translateX(115%);
  }
`

const NotificationSideBar = memo(({ toggled, setToggled }) => {
  const { loginData, setLoginData } = useContext(LoginData)
  const [currentPage, setCurrentPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [notifications, setNotifications] = useState(
    loginData?.notifications
      ?.slice()
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || []
  )

  useEffect(() => {
    if (loginData) {
      setNotifications(
        loginData?.notifications
          ?.slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || []
      )
      setCanLoadMore(loginData?.notifications?.length >= 15 || false)
    }
  }, [loginData])

  //Lorsque on ferme la sidebar on marque les notifs comme lues
  const handleCloseSideBar = () => {
    setToggled(false)

    if (notifications.some((notif) => !notif.read)) {
      setNotifications((prevNotifS) =>
        prevNotifS.map((notif) => ({
          ...notif,
          read: true,
        }))
      )
      setLoginData((prevLoginData) => ({
        ...prevLoginData,
        notifications: prevLoginData.notifications.map((notif) => ({
          ...notif,
          read: true,
        })),
      }))

      axios({
        method: 'put',
        data: {},
        withCredentials: true,
        url: `${process.env.REACT_APP_IP_ADRESS}/api/user/notification/mark-all-read`,
      })
        .then((res) => console.log(res.data.message))
        .catch((error) => {
          console.error(error)
        })
    }
  }
  const loadMoreNotifications = () => {
    if (canLoadMore) {
      setIsLoadingMore(true)
      const nextPage = currentPage + 1

      axios
        .get(
          `${process.env.REACT_APP_IP_ADRESS}/api/user/notification/?page=${nextPage}`,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          const { notifications } = res.data

          setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...notifications,
          ])
          setCurrentPage(nextPage)
          if (notifications.length <= 14) setCanLoadMore(false)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => setIsLoadingMore(false))
    }
  }

  const handleDecline = (notif) => {
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_IP_ADRESS}/api/user/follow/decline`,
      withCredentials: true,
      data: { notif: notif },
    }

    axios(config)
      .then((res) => {
        console.log(res.data)
        toast.success('Follow request declined with success.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 1000,
        })
        setLoginData((prevLoginData) => ({
          ...prevLoginData,
          notifications: prevLoginData.notifications.filter((prevNotifs) =>
            prevNotifs._id ? prevNotifs._id !== notif._id : true
          ),
          followRequests: prevLoginData.followRequests.filter(
            (prevReq) =>
              prevReq.toString() !== notif.data.follower._id.toString()
          ),
        }))
      })
      .catch((err) => console.log(err))
  }
  const handleAccept = (notif) => {
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_IP_ADRESS}/api/user/follow/accept`,
      withCredentials: true,
      data: { notif: notif },
    }

    axios(config)
      .then((res) => {
        console.log(res.data)
        toast.success('Follow request accepted with success.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 1000,
        })
        setLoginData((prevLoginData) => ({
          ...prevLoginData,
          notifications: prevLoginData.notifications.filter((prevNotifs) =>
            prevNotifs._id ? prevNotifs._id !== notif._id : true
          ),
          followRequests: prevLoginData.followRequests.filter(
            (prevReq) =>
              prevReq.toString() !== notif.data.follower._id.toString()
          ),
        }))
      })
      .catch((err) => console.log(err))
  }

  const removeNotif = (notifId) => {
    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      notifications: prevLoginData.notifications.filter((prevNotifs) =>
        prevNotifs._id ? prevNotifs._id !== notifId : true
      ),
    }))
  }

  const renderNotificationCards = (isRead) =>
    notifications.map((notif) => {
      if (notif.read !== isRead) return null
      return (
        <div
          key={notif.createdAt}
          className="flex flex-row items-center justify-center ml-2"
        >
          {notif.type === 'like_list' && (
            <LikeListNotifCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'new_follower' && (
            <NewFollowerNotifCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'follow_request' && (
            <NewFollowRequestCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
              handleDecline={handleDecline}
              handleAccept={handleAccept}
            />
          )}
          {notif.type === 'accepted_follow' && (
            <AcceptedFollowCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'list_collab_invitation' && (
            <ListCollabInvitationCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
              removeNotif={removeNotif}
            />
          )}
          {notif.type === 'accepted_collab' && (
            <AcceptedListCollab
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'unlocked_badge' && (
            <UnlockedBadge
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'like_review' && (
            <LikeReviewNotifCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'like_reply' && (
            <LikeReplyNotifCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
          {notif.type === 'replying' && (
            <ReplyToMyReviewNotifCard
              notif={notif}
              handleCloseSideBar={handleCloseSideBar}
              isRead={isRead}
            />
          )}
        </div>
      )
    })

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        minHeight: '400px',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 999,
      }}
    >
      <Sidebar
        image={loginData.coverPic}
        onBackdropClick={handleCloseSideBar}
        toggled={toggled}
        // width={300}
        style={{
          borderColor: colors.backgroundDerivation,
          maxWidth: 300,
          width: toggled ? 300 : 0,
        }}
        className="shadow-xl overflow-y-scroll  scrollbar-none "
        breakPoint="all"
        rtl={true}
        collapsedWidth={0}
        backgroundColor={'rgb(8, 7, 27,0.9)'}
      >
        <div
          className="flex flex-col w-full items-center justify-center mt-8"
          style={{ direction: 'ltr' }}
        >
          <h2>NOTIFICATIONS</h2>
          {/* <label>
            {loginData.followRequests?.length || 0} demande d'abonnements :{' '}
          </label> */}

          {notifications.length > 0 ? (
            <>
              {notifications.some((notif) => !notif.read) ? (
                <SectionTitle color={colors.danger} className="mt-6 mb-2">
                  Newests
                </SectionTitle>
              ) : (
                <h3 className="text-slate-300 font-normal text-normal mt-6">
                  There's no new notif yet.
                </h3>
              )}

              <div className="flex flex-col">
                {renderNotificationCards(false)}
              </div>
              {notifications.some((notif) => notif.read) && (
                <>
                  <SectionTitle color="gray" className="mt-6 mb-2">
                    History
                  </SectionTitle>
                  <div className="flex flex-col">
                    {renderNotificationCards(true)}
                  </div>
                </>
              )}
              {canLoadMore && (
                <div className="frame justify-center items-center">
                  {isLoadingMore ? (
                    <div
                      className="relative justify-center items-center  flex flex-row"
                      style={{ transform: 'scale(0.5)' }}
                    >
                      <div className="circle-1 circles"></div>
                      <div className="circle-2 circles"></div>
                      <div className="circle-3 circles"></div>
                    </div>
                  ) : (
                    <button
                      className="custom-btn btn-11"
                      onClick={loadMoreNotifications}
                    >
                      LOAD MORE
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <h3 className="text-slate-300 mt-6">
              There are no notifications yet.
            </h3>
          )}
        </div>
      </Sidebar>
    </div>
  )
})

export default NotificationSideBar
