import styled from 'styled-components'
import {
  MdVideogameAssetOff,
  MdOutlineVideogameAssetOff,
  MdVideogameAsset,
  MdOutlineVideogameAsset,
  MdBookmarkAdd,
  MdBookmarkAdded,
  MdOutlineClose,
} from 'react-icons/md'

import {
  TbSquareCheck,
  TbSquareCheckFilled,
  TbSquareRounded,
  TbSquareRoundedCheck,
  TbSquareRoundedCheckFilled,
} from 'react-icons/tb'

import colors from '../../../utils/style/colors'
import MyPlayLogIcon from '../../../assets/LOGO_placeholder.png'
import { BsSteam } from 'react-icons/bs'
import { memo } from 'react'
import i18next from 'i18next'

const GameWrapper = styled.div`
  transition: transform 0.1s ease;
  &:hover .background,
  &:hover div img,
  &:hover div .check {
    transform: translateX(5px);
    cursor: pointer;
  }
`

const GameCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  z-index: 5;

  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  ${(p) =>
    p.selected
      ? '  '
      : '  backdrop-filter: blur(5px);  &:hover img { box-shadow: -5px 0px #fcd500;} '}

  &:hover {
    backdrop-filter: blur(0px);
  }
`

const GameBackground = styled.div`
  z-index: -1;
`
const GameCover = styled.div`
  transition: transform 0.1s ease;
  width: 150px;
  position: relative;
`

const GameImg = styled.img`
  ${(p) =>
    p.selected
      ? `   box-shadow: -5px 0px ${colors.favorable};
`
      : ''}

  height: calc(90px + 13.5vh);
  width: 90%;
  object-fit: cover;
`

const StatusButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  width: fit-content;
  gap: 3px;
  cursor: pointer;
`

const PendingGamesCard = memo(
  ({
    game,
    handleChangeStatus,
    handleIgnoreGame,
    handleAddGamesToCollection,
    handleCheckGame,
  }) => {
    const iconSize = 24
    console.log('render ' + game.name)

    return (
      <GameWrapper
        // onClick={() => handleCheckGame(game.id_IGDB)}
        key={`game ${game.slug}`}
        // to={`/game/${game.slug}`}
        className="text-white hover:text-white relative"
      >
        <GameBackground
          className="background"
          selected={game.selected}
          style={{
            height: '100%',
            width: '92%',
            position: 'absolute',
            right: 0,
            background:
              game.background &&
              `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("https://images.igdb.com/igdb/image/upload/t_1080p/${
                game.background ? game.background : 'undefined'
              }.jpg")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
        <GameCard selected={game.selected}>
          <button
            onClick={() => handleIgnoreGame(game)}
            className=" absolute top-5 right-5 cursor-pointer bg-transparent border-none text-white  hover:text-[#FF312E]"
          >
            <MdOutlineClose size={iconSize} />
          </button>
          <GameCover>
            <GameImg
              selected={game.selected}
              src={
                game.cover
                  ? `https://images.igdb.com/igdb/image/upload/t_cover_big/${
                      game.cover ? game.cover : 'undefined'
                    }.jpg`
                  : MyPlayLogIcon
              }
              alt={'Cover of ' + game.name}
            />
            {/* {game.selected && (
              <div className="absolute flex border-none  top-3  right-7 bg-transparent justify-center items-center ">
                <CheckButton
                  className="   rounded-lg border-none check  shadow-lg"
                  onClick={() => handleCheckGame(game.id_IGDB)}
                >
                  <TbSquareCheckFilled color={colors.primary} size={35} />
                </CheckButton>
              </div>
            )} */}
          </GameCover>
          <div className="flex flex-col gap-2 pl-4">
            <h2>{game.name}</h2>
            <div className=" flex items-center flex-row gap-2">
              <p>
                {game.timePlayed === 0 ? (
                  'Never Played'
                ) : (
                  <>
                    {' '}
                    Played{' '}
                    <strong style={{ color: colors.primary }}>
                      {game.timePlayed} hours
                    </strong>
                    {game.lastPlayed && (
                      <>
                        {' '}
                        | Last time played{' '}
                        <strong style={{ color: colors.primary }}>
                          {new Intl.DateTimeFormat(i18next.language).format(
                            new Date(game.lastPlayed)
                          )}
                        </strong>
                      </>
                    )}
                  </>
                )}
              </p>
              <BsSteam color="white" size={iconSize} />
            </div>

            <div>
              <div className="flex flex-wrap flex-row gap-2">
                <StatusButton
                  className={`  border-solid ${
                    game.status === 0
                      ? 'border-[#fcd500] text-black bg-[#fcd500]'
                      : 'bg-transparent text-white border-white hover:bg-white hover:text-black  '
                  }`}
                  onClick={() => handleChangeStatus(0, game.id_IGDB)}
                >
                  {game.status === 0 ? (
                    <MdBookmarkAdded size={iconSize} />
                  ) : (
                    <MdBookmarkAdd size={iconSize} />
                  )}
                  <span>Wishlist</span>
                </StatusButton>

                <StatusButton
                  className={`  border-solid ${
                    game.status === 1
                      ? 'bg-[#FF312E] text-white border-[#FF312E]'
                      : 'bg-transparent text-white border-white hover:bg-white hover:text-black  '
                  }`}
                  onClick={() => handleChangeStatus(1, game.id_IGDB)}
                >
                  {game.status === 1 ? (
                    <MdVideogameAssetOff size={iconSize} />
                  ) : (
                    <MdOutlineVideogameAssetOff size={iconSize} />
                  )}
                  <span>Stopped</span>
                </StatusButton>

                <StatusButton
                  className={`  border-solid ${
                    game.status === 2
                      ? 'bg-[#fcd500] text-[rgb(8, 7, 27)] border-[#fcd500]'
                      : 'bg-transparent text-white border-white hover:bg-white hover:text-black  '
                  }`}
                  onClick={() => handleChangeStatus(2, game.id_IGDB)}
                >
                  {game.status === 2 ? (
                    <MdVideogameAsset size={iconSize} />
                  ) : (
                    <MdOutlineVideogameAsset size={iconSize} />
                  )}
                  <span>Playing</span>
                </StatusButton>

                <StatusButton
                  className={`  border-solid ${
                    game.status === 3
                      ? 'bg-[#27ae60] text-white border-[#27ae60]'
                      : 'bg-transparent text-white border-white hover:bg-white hover:text-black  '
                  }`}
                  onClick={() => handleChangeStatus(3, game.id_IGDB)}
                >
                  {game.status === 3 ? (
                    <MdVideogameAsset size={iconSize} />
                  ) : (
                    <MdOutlineVideogameAsset size={iconSize} />
                  )}
                  <span>Finished</span>
                </StatusButton>
              </div>
              <button
                className={`mt-3 p-2 flex  font-semibold flex-row justify-center items-center gap-1 text-white ] 
                   border-slate-300  rounded-lg cursor-pointer`}
                style={{
                  backdropFilter: 'blur(18px)',
                  border: `2px solid ${game.selected ? 'transparent' : 'gray'}`,
                  background: game.selected
                    ? 'rgb(39 174 96 / 95%)'
                    : ' rgba(15, 20, 25, 0.5)',
                }}
                onClick={() => handleCheckGame(game.id_IGDB)}
              >
                {game.selected && (
                  <TbSquareCheckFilled color={'white'} size={18} />
                )}
                {game.selected ? 'READY TO BE ADDED' : 'APPROVE THIS GAME'}
              </button>
            </div>
          </div>
        </GameCard>
      </GameWrapper>
    )
  }
)

export default PendingGamesCard
