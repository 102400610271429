import { Modal, Spin } from 'antd'
import React, { useContext, useState } from 'react'
import {
  SearchOutlined,
  TrophyOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import colors from '../../../../utils/style/colors'
import psnLoading from '../../../../assets/images/LoadingGif/psn.gif'
import axios from 'axios'
import { LoginData } from '../../../../Helper/Context'

const PsnLinkingModal = ({ isModalOpen, setIsModalOpen }) => {
  const { loginData, setLoginData } = useContext(LoginData)

  const [inputText, setInputText] = useState('')
  const [isSearchingProfile, setIsSearchingProfile] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [profileData, setProfileData] = useState(null)

  const [searchFFT, setSearchFFT] = useState(false)

  const [importingGames, setImportingGames] = useState(false)
  const [GamesImported, setGamesImported] = useState(null)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleChange = (e) => {
    setInputText(e.target.value)
  }

  const handleClick = () => {
    if (inputText) {
      setSearchFFT(true)
      setIsSearchingProfile(true)
      axios
        .get(`${process.env.REACT_APP_IP_ADRESS}/api/psn/user/${inputText}`)
        .then((response) => {
          setIsSearchingProfile(false)
          if (response.data.message === 'profile found') {
            setProfileData(response.data.profile)
          } else {
            setProfileData(null)
          }
        })
        .catch((error) => {
          setIsSearchingProfile(false)
        })
    }
  }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        fontWeight: 'bold',
        color: colors.primary,
      }}
      spin
    />
  )

  const handleUpdate = () => {
    setImportingGames(true)
    const data = {
      username: profileData.onlineId,
      psnId: profileData.accountId,
      npCommunicationId: profileData.npId,
      avatarUrl: profileData.avatarUrls[0].avatarUrl,
      aboutMe: profileData.aboutMe,
      trophySummary: profileData.trophySummary,
    }

    axios
      .post(
        `${process.env.REACT_APP_IP_ADRESS}/api/psn/psnAccount/update`,
        data,
        { withCredentials: true }
      )
      .then((response) => {
        if ((response.data.message = 'PSN account updated successfully')) {
          //yjib lgames te3ek

          axios
            .get(
              `${process.env.REACT_APP_IP_ADRESS}/api/psn/psnAccount/games`,
              { withCredentials: true }
            )
            .then((response) => {
              setImportingGames(false)
              if (response.data) {
                setGamesImported(response.data)
                setLoginData({
                  ...loginData,
                  pendingGames: response.data.pendingGames,
                  psnAccount: data,
                })
              } else {
                setGamesImported(null)
              }
            })
            .catch((error) => {
              setGamesImported(null)
            })
        } else {
          setImportingGames(false)
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error(error)
      })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <Modal
        title={'PsnLinking'}
        open={isModalOpen}
        className="steamModal"
        onCancel={handleCancel}
        style={{ minWidth: '300px' }}
        footer={[]}
      >
        <div className=" mt-4 flex flex-row items-center justify-center">
          <input
            type="text"
            value={inputText}
            onChange={handleChange}
            className="p-2 h-10 flex-1 bg-white/20 border-transparent rounded-full text-white font-bold  "
            placeholder="Search by your PSN username..."
          />
          <button
            onClick={handleClick}
            className="p-2 text-white rounded-full border-transparent ml-1 cursor-pointer relative overflow-hidden"
            style={{
              backgroundColor: colors.primary,
              transition: 'background-color 0.3s',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <SearchOutlined
              style={{
                color: colors.backgroundDerivation,
                fontSize: 20,
                transform: isHovered ? 'scale(1.2)' : 'scale(1)',
                transition: 'transform 0.3s',
              }}
            />
          </button>
        </div>

        {isSearchingProfile ? (
          <div className="flex flex-col justify-center items-center">
            <img src={psnLoading} alt="PSN Loading" className="mt-4 w-20 " />
            <h5>Searching for your PSN Account...</h5>
          </div>
        ) : profileData ? (
          <div className="mt-2 flex flex-col justify-center items-center">
            <img
              src={profileData.avatarUrls[0].avatarUrl}
              alt="PSN Loading"
              className="mt-4 w-20 rounded-full "
            />
            <h4> {profileData.onlineId} </h4>
            <div className="flex flex-row justify-center items-center">
              <p className="m-1">
                level :{' '}
                <span style={{ color: colors.primary }}>
                  {profileData.trophySummary.level}
                </span>{' '}
              </p>
              <p className="m-1">
                progress :{' '}
                <span className="text-green-700">
                  {profileData.trophySummary.progress}{' '}
                </span>
              </p>
            </div>

            <div className="flex flex-row justify-center items-center">
              <div className="flex flex-col justify-center items-center m-2">
                <TrophyOutlined className="text-lg -mb-2 text-amber-700" />
                <span className="text-xs text-amber-700 font-bold ">
                  {profileData.trophySummary.earnedTrophies.bronze}
                </span>
              </div>
              <div className="flex flex-col justify-center items-center m-2">
                <TrophyOutlined className="text-lg -mb-2 text-gray-500" />
                <span className="text-xs font-bold text-gray-500">
                  {profileData.trophySummary.earnedTrophies.silver}
                </span>
              </div>
              <div className="flex flex-col justify-center items-center m-2">
                <TrophyOutlined className="text-lg -mb-2 text-yellow-500" />
                <span className="text-xs font-bold text-yellow-500">
                  {profileData.trophySummary.earnedTrophies.gold}
                </span>
              </div>
              <div className="flex flex-col justify-center items-center m-2">
                <TrophyOutlined className="text-lg -mb-2 text-slate-300" />
                <span className="text-xs font-bold text-slate-300 ">
                  {profileData.trophySummary.earnedTrophies.platinum}
                </span>
              </div>
            </div>
            {!GamesImported ? (
              <button
                onClick={handleUpdate}
                className="p-1 text-black font-bold text-sm bg-green-700 rounded-full border-transparent mt-1 cursor-pointer relative overflow-hidden"
              >
                Yes, it's me
              </button>
            ) : (
              <div>
                <p>
                  {' '}
                  <span style={{ color: colors.primary }}>
                    {GamesImported.found}
                  </span>{' '}
                  of your games are imported, you can check them in Pending
                  games{' '}
                </p>
              </div>
            )}

            {importingGames && <Spin className="mt-2" indicator={antIcon} />}
          </div>
        ) : (
          searchFFT && (
            <>
              <h5 className="mx-auto">there's no account with that name</h5>
            </>
          )
        )}
      </Modal>
    </div>
  )
}

export default PsnLinkingModal
