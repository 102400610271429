import React, { useContext, useEffect, useState } from 'react'
import { LoginData } from '../../../../Helper/Context'
import { Loader, SuccessAnimation } from '../../../../utils/Atoms'
import axios from 'axios'
import colors from '../../../../utils/style/colors'
import { Divider } from 'antd'
import ImportAchievements from './ImportAchievements'

export default function ImportSteamGames() {
  const { loginData, setLoginData } = useContext(LoginData)
  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState('')
  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_IP_ADRESS}/api/steam/games/${loginData.steamAccount.steamId}}`,
        { withCredentials: true }
      )
      .then((res) => {
        console.log(res.data.message)
        setResult(res.data)
        setLoginData((prevLoginData) => {
          return {
            ...prevLoginData,
            pendingGames: res.data.pendingGames,
            games: res.data.newGames,
          }
        })
      })
      .catch((err) => console.log(err))
      .then(() => {
        if (result.pendingGames.length === 0) setIsLoading(false)
      })
  }, [])

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-row gap-2">
          <div className="flex flex-col ">
            <img
              alt={`profile pic of ${loginData.steamAccount.steamUsername}`}
              src={loginData.steamAccount.steamPic}
              className="h-24 w-24 object-cover"
            />
            <h2>{loginData.steamAccount.steamUsername}</h2>
          </div>
        </div>
        {isLoading ? (
          <>
            <div>
              <h3>Steam account Synced with success.</h3>
            </div>
            <div className="flex justify-center items-center gap-4 flex-col">
              Wait we're importing you're games...
              {result === '' ? (
                <div className="flex justify-start items-start" l>
                  <div className="achievements-loader ">
                    <p className=" flex-nowrap break-words">
                      Synchronizing your
                    </p>
                    <div className="achievements-container">
                      <span className="achievements-word flex-nowrap break-words overflow-hidden">
                        games
                      </span>
                      <span className="achievements-word flex-nowrap break-words overflow-hidden">
                        play time
                      </span>
                      <span className="achievements-word flex-nowrap break-words overflow-hidden">
                        achievements
                      </span>
                      <span className="achievements-word flex-nowrap break-words overflow-hidden">
                        stats
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <ImportAchievements
                  pendingGames={result.pendingGames}
                  setIsLoading={setIsLoading}
                  setLoginData={setLoginData}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <SuccessAnimation />

            <div>
              <h3>
                <strong style={{ color: colors.favorable }}>
                  {result.totalFind}
                </strong>{' '}
                from your{' '}
                <strong style={{ color: colors.favorable }}>
                  {result.totalUserGames}
                </strong>{' '}
                games imported with{' '}
                <span style={{ color: colors.favorable }}>success</span>.
              </h3>
              <h4 className="text-slate-300 font-medium">
                <strong style={{ color: colors.favorable }}>
                  {result.totalSynchronized}
                </strong>{' '}
                games synchronized. |{' '}
                <strong style={{ color: colors.average }}>
                  {result.pendingGames.length}
                </strong>{' '}
                games waiting your approval.
              </h4>
              <Divider style={{ background: 'white' }} className="mx-0 my-3" />
              <h4 className="mb-2  text-xs text-slate-300">
                <strong style={{ color: colors.unfavorable }}>
                  {result.gamesNotFound.length}
                </strong>{' '}
                IGNORED GAMES :
              </h4>
              <div className="flex flex-row gap-2 flex-wrap">
                {result.gamesNotFound.map((game) => {
                  return (
                    <div
                      key={'ignored_game_' + game.steamAppId.toString()}
                      className="flex-row flex gap-1 px-2 py-1 shadow-lg rounded-md"
                      style={{ border: `1px solid gray` }}
                    >
                      <img
                        src={game.cover}
                        alt={`Game cover of ${game.name}`}
                        className=" w-6 h-6"
                      />
                      <label>{game.name}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        )}

        <div></div>
      </div>
    </div>
  )
}
