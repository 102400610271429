import i18next from 'i18next'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

export const formatDate = (date) => {
  const formatDate = new Date(date)

  // On calcule la différence de temps entre le post et maintenant.
  const now = new Date()
  const diff = now.getTime() - formatDate.getTime()
  const diffInHours = Math.floor(diff / (1000 * 60 * 60))
  const diffInMinutes = Math.floor(diff / (1000 * 60))

  // Si ça a été posté avant cette année, la différence est en année.
  const diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
  if (diffInYears > 0) {
    return new Intl.RelativeTimeFormat(i18next.language, {
      numeric: 'auto',
    }).format(-diffInYears, 'year')
  }

  // Si ça a été posté cette année, la différence est en jours.
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (diffInDays > 0) {
    return new Intl.RelativeTimeFormat(i18next.language, {
      numeric: 'auto',
    }).format(-diffInDays, 'day')
  }

  // Si ça a été posté aujourd'hui, la différence est en heures, minutes ou à l'instant.
  if (diffInHours > 0) {
    return new Intl.RelativeTimeFormat(i18next.language, {
      numeric: 'auto',
    }).format(-diffInHours, 'hour')
  } else if (diffInMinutes > 0) {
    return new Intl.RelativeTimeFormat(i18next.language, {
      numeric: 'auto',
    }).format(-diffInMinutes, 'minute')
  } else {
    return i18next.language === 'fr' ? "à l'instant" : 'just now'
  }
}

export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
