import React from 'react'
import colors from '../../../utils/style/colors'
import { Button } from 'antd'

export default function DeleteAccountTab() {
  return (
    <div className="w-1/3">
      <div
        className="flex flex-col gap-5 p-6 rounded-lg justify-center items-center   shadow-xl overflow-hidden"
        style={{ background: colors.backgroundDerivation }}
      >
        <div className="flex flex-col gap-5">
          <h2> Supprimer le compte </h2>
          <div className="flex flex-row  items-center  w-full justify-between">
            <label className="text-lg mr-4">Are you sure ? </label>
            <div className="flex flex-col  p-4  justify-center items-center  ">
              <Button>Delete</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
