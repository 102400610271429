import React, { useState } from 'react'
import colors from '../../../utils/style/colors'
import axios from 'axios'
import { toast } from 'react-toastify'

const Feedbacks = () => {
  const [feedback, setFeedback] = useState('')

  const handlePostFeedback = () => {
    axios
      .post(
        `${process.env.REACT_APP_IP_ADRESS}/api/admin/feedback`,
        { text: feedback },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.message === 'Feedback posted successfully') {
          toast.success('Feedback posted successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        } else {
          toast.error('error occurred', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        }
      })
      .catch((error) => {
        console.error('Error sending email :', error)
      })
  }

  return (
    <div
      className="w-4/6 h-auto p-4 rounded-md relative"
      style={{ backgroundColor: colors.backgroundDerivation }}
    >
      <button
        style={{
          backgroundColor: colors.primary,
          color: colors.backgroundDerivation,
        }}
        className="rounded-full pl-2 pr-2 border-transparent absolute top-4 right-4 font-bold text-base cursor-pointer"
        onClick={handlePostFeedback}
      >
        Send
      </button>
      <h1 className="text-3xl font-semibold text-white mb-4">Your Feedback</h1>
      <textarea
        value={feedback}
        onChange={(e) => {
          setFeedback(e.target.value)
        }}
        className="w-full h-96 p-2 bg-white/20 border-transparent rounded-md text-white font-medium resize-none text-base"
        placeholder="Enter your feedback"
      ></textarea>
    </div>
  )
}

export default Feedbacks
