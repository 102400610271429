import { Button, Modal, ConfigProvider, Input } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'
import { useTranslation } from 'react-i18next'

import { LoginData } from '../../../Helper/Context'
import colors from '../../../utils/style/colors'
import EditIcon from '../../../assets/icons/editing.png'
import BgImg from '../../../assets/images/background/bg_01.gif'
import SelectProfilePic from '../../../pages/UserPage/EditUser/SelectImages/SelectProfilePic'
import SelectCover from '../../../pages/UserPage/EditUser/SelectImages/SelectCover'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import ToggleBear from '../NotificationsTab/ToggleBear'
import SectionPrivateAccount from './SectionPrivateAccount/SectionPrivateAccount'

const { TextArea } = Input

const ProfilePicture = styled.img`
  border: 2px solid ${colors.primary};
  box-shadow: 0px 5px 8px #000000;
  border-radius: 9999px;
`
const EditButton = styled.button`
  background-color: rgba(15, 20, 25, 0.75);
  &:hover {
    background-color: rgba(15, 20, 25, 0.8);
    cursor: pointer;
    backdrop-filter: blur(4px);
  }
  z-index: 2;
`

const Section = styled.section`
  width: 100%;
  display: block;
  margin-bottom: 2rem;
`
const SectionHeader = styled.div`
  padding: 0 2em 0 0;
  grid-column: 1;
  color: var(--cp-color-6);
`
const SectionTitle = styled.h3`
  font-size: 1.2rem;
  color: white;
`

const SectionProfilePicture = styled.div`
  display: grid;
  grid-template-columns: auto minmax(50%, 1fr);
  grid-gap: 1rem;
  background: rgba(8, 7, 27, 1);
  border-radius: 6px;
  padding: 2rem;
  grid-column: 2;
`

const SectionSubtitle = styled.label`
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 0.125em;
`

export default function ProfileTab() {
  const { loginData, setLoginData } = useContext(LoginData)
  return (
    <div>
      <Section>
        <SectionHeader>
          <SectionTitle>Profile Picture</SectionTitle>
        </SectionHeader>
        <SectionProfilePicture>
          <div className="relative " style={{ zIndex: 1 }}>
            <ProfilePicture
              alt="profilePicture"
              className="  w-28 h-28 object-cover rounded-full  "
              src={loginData.profilePic ? loginData.profilePic : ''}
            />
            <EditButton
              className="absolute top-0 left-0 w-fit right-0 bottom-0 m-auto h-fit bg-transparent border-none p-3  rounded-full "
              onClick={() => {}}
            >
              <img src={EditIcon} alt="edit" className=" w-4" />
            </EditButton>
          </div>
          <div className="flex flex-col gap-2 items-center justify-center">
            <SectionSubtitle>Change your profil pic</SectionSubtitle>
            <p className=" w-60 text-sm  text-slate-400 font-sans ">
              Choose from dozens of characters from various gaming licenses to
              stand out, or{' '}
              <Link
                style={{ color: colors.primary }}
                to="/settings?tab=linked_accounts"
              >
                {' '}
                link{' '}
              </Link>{' '}
              your Google or Steam accounts to retrieve your photos.
            </p>
          </div>
        </SectionProfilePicture>
      </Section>
      <SectionHeader>
        <SectionTitle>Privacy</SectionTitle>
      </SectionHeader>
      <SectionPrivateAccount />
    </div>
  )
}
