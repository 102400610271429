import axios from 'axios'
import React, { useEffect } from 'react'
import colors from '../../../../utils/style/colors'

export default function ImportAchievements({
  pendingGames,
  setIsLoading,
  setLoginData,
}) {
  useEffect(() => {
    if (pendingGames) {
      axios
        .get(`${process.env.REACT_APP_IP_ADRESS}/api/steam/user/achievements`, {
          withCredentials: true,
        })
        .then((res) => {
          console.log(res.data.message)
          setLoginData(res.data.newUser)
        })
        .catch((err) => console.log(err))
        .then(() => setIsLoading(false))
    }
  }, [pendingGames])

  return (
    <div className="flex flex-col  gap-2 justify-center  items-center ">
      <div className="achievements-loader">
        <p
          className=" flex-nowrap break-words "
          style={{ color: colors.primary }}
        >
          Games synchronized
        </p>
      </div>

      <div className=" flex flex-row">
        <div className="achievements-loader ">
          <p className=" flex-nowrap break-words">Importing</p>
          <div className="achievements-container">
            {pendingGames.map((game) => (
              <span
                key={game.steamAppId}
                className="achievements-word flex-nowrap break-words overflow-hidden"
              >
                {game.name} <label className="text-white">...</label>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
