import colors from '../../../../utils/style/colors'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../../utils/hooks/Hooks'
import { BsHeartFill } from 'react-icons/bs'
import {
  RiUserAddFill,
  RiUserFollowFill,
  RiUserFollowLine,
} from 'react-icons/ri'
import { FiCornerUpLeft } from 'react-icons/fi'
import {
  MdFormatListBulleted,
  MdFormatListBulletedAdd,
  MdPlaylistAddCheck,
} from 'react-icons/md'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useRef } from 'react'
import { useState } from 'react'
import badges from '../../../../utils/data/Badges'

const NotifCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid ${({ read }) => (read ? 'gray' : colors.primary)};
  padding: 5px;
  border-radius: 15px;
  max-width: 240px;
  min-width: 240px;
  overflow: hidden;
`

const StyledLink = styled(Link)`
  color: ${colors.primary};
  font-weight: 700;
`

export const LikeListNotifCard = ({ isRead, notif, handleCloseSideBar }) => (
  <>
    <BsHeartFill color={'#ea442b'} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.liker._id}`}>
        <img
          alt={`${notif.data.liker.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.liker.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.liker._id}`}
        >
          {notif.data.liker.username}
        </StyledLink>{' '}
        liked your list{' '}
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/list/${notif.data.listId}`}
        >
          {notif.data.listName}
        </StyledLink>
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)

export const NewFollowerNotifCard = ({ isRead, notif, handleCloseSideBar }) => (
  <>
    <RiUserAddFill color={colors.favorable} size={25} />

    <NotifCard read={isRead}>
      <Link
        onClick={handleCloseSideBar}
        to={`/user/${notif.data.follower._id}`}
      >
        <img
          alt={`${notif.data.follower.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.follower.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.follower._id}`}
        >
          {notif.data.follower.username}
        </StyledLink>{' '}
        is now following you.
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)

export const NewFollowRequestCard = ({
  isRead,
  notif,
  handleCloseSideBar,
  handleDecline,
  handleAccept,
}) => {
  const [isAnimating, setIsAnimating] = useState(false)

  return (
    <>
      <RiUserAddFill color={colors.primary} size={25} />

      <NotifCard read={isRead}>
        <Link
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.follower._id}`}
        >
          <img
            alt={`${notif.data.follower.username} profile pic`}
            className="h-10 w-10 object-cover rounded-full"
            src={notif.data.follower.profilePic}
          />
        </Link>

        <label>
          <StyledLink
            onClick={handleCloseSideBar}
            to={`/user/${notif.data.follower._id}`}
          >
            {notif.data.follower.username}
          </StyledLink>{' '}
          want to follow you.
        </label>

        <h5 className="text-sm text-slate-400">
          {formatDate(notif.createdAt)}.
        </h5>
        <div className=" flex flex-row gap-2 px-2 py-1">
          <div className="flex w-fit relative accept-wrapper">
            <button
              className={`bubbly-button accept ${isAnimating ? 'animate' : ''}`}
              onClick={() => {
                setIsAnimating(true)

                setTimeout(() => {
                  setIsAnimating(false)
                }, 700)

                handleAccept(notif)
              }}
            >
              Accept
            </button>
          </div>
          <button
            className="bubbly-button decline"
            onClick={() => handleDecline(notif)}
          >
            Decline
          </button>
        </div>
      </NotifCard>
    </>
  )
}

export const AcceptedFollowCard = ({ isRead, notif, handleCloseSideBar }) => (
  <>
    <RiUserFollowFill color={colors.favorable} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.user._id}`}>
        <img
          alt={`${notif.data.user.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.user.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.user._id}`}
        >
          {notif.data.user.username}
        </StyledLink>{' '}
        has accepted your follow request.
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)

export const ListCollabInvitationCard = ({
  isRead,
  notif,
  handleCloseSideBar,
  removeNotif,
}) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const handleClick = async (accept) => {
    const listId = notif.data.listId
    try {
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_IP_ADRESS}/api/lists/${listId}/${
          accept ? 'accept' : 'decline'
        }`,
        withCredentials: true,
        data: {
          notif_id: notif._id,
        },
      }

      await axios(config).then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 1000,
        })
      })
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
      })
    } finally {
      removeNotif(notif._id)
    }
  }

  return (
    <>
      <MdFormatListBulletedAdd color={colors.primary} size={25} />

      <NotifCard read={isRead}>
        <Link onClick={handleCloseSideBar} to={`/user/${notif.data.owner._id}`}>
          <img
            alt={`${notif.data.owner.username} profile pic`}
            className="h-10 w-10 object-cover rounded-full"
            src={notif.data.owner.profilePic}
          />
        </Link>
        <label>
          <StyledLink
            onClick={handleCloseSideBar}
            to={`/user/${notif.data.owner._id}`}
          >
            {notif.data.owner.username}
          </StyledLink>{' '}
          has invited you to collaborate on the list{' '}
          <StyledLink
            onClick={handleCloseSideBar}
            to={`/list/${notif.data.listId}`}
          >
            {notif.data.listName}
          </StyledLink>
        </label>

        <h5 className="text-sm text-slate-400">
          {formatDate(notif.createdAt)}.
        </h5>
        <div className=" flex flex-row gap-2 px-2 py-1">
          <button
            className={`bubbly-button accept ${isAnimating ? 'animate' : ''}`}
            onClick={() => {
              setIsAnimating(true)

              setTimeout(() => {
                setIsAnimating(false)
              }, 700)

              handleClick(true)
            }}
          >
            Accept
          </button>
          <button
            className="bubbly-button decline"
            onClick={() => handleClick(false)}
          >
            Decline
          </button>
        </div>
      </NotifCard>
    </>
  )
}

export const AcceptedListCollab = ({ isRead, notif, handleCloseSideBar }) => (
  <>
    <MdPlaylistAddCheck color={colors.favorable} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.user._id}`}>
        <img
          alt={`${notif.data.user.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.user.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.user._id}`}
        >
          {notif.data.user.username}
        </StyledLink>{' '}
        is now collaborator on your list{' '}
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/list/${notif.data.listId}`}
        >
          {notif.data.listName}
        </StyledLink>
        .
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)
///////////////////////////////////------REVIEW  --------------------------------------------//////////////////////////////////////////////////////////

export const LikeReviewNotifCard = ({ isRead, notif, handleCloseSideBar }) => (
  <>
    <BsHeartFill color={'#ea442b'} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.liker._id}`}>
        <img
          alt={`${notif.data.liker.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.liker.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.liker._id}`}
        >
          {notif.data.liker.username}
        </StyledLink>{' '}
        liked your review{' '}
        {/* <StyledLink
          onClick={handleCloseSideBar}
          to={`/list/${notif.data.listId}`}
        >
          {notif.data.listName}
        </StyledLink> */}
        <p className="line-clamp-2 truncate overflow-hidden">
          " {notif.data.reviewText} "
        </p>
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)

export const ReplyToMyReviewNotifCard = ({
  isRead,
  notif,
  handleCloseSideBar,
}) => (
  <>
    <FiCornerUpLeft color={'white'} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.replier._id}`}>
        <img
          alt={`${notif.data.replier.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.replier.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.replier._id}`}
        >
          {notif.data.replier.username}
        </StyledLink>{' '}
        replied to your review{' '}
        {/* <StyledLink
          onClick={handleCloseSideBar}
          to={`/list/${notif.data.listId}`}
        >
          {notif.data.review.text}
        </StyledLink> */}
        <p className="line-clamp-2 truncate overflow-hidden text-xs">
          "{notif.data.reviewText}"
        </p>{' '}
        <p>by " {notif.data.replyText} "</p>
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)

//////////////////////////////////////// REPLIES  ------------------------------------------///////////////////////////////////

export const LikeReplyNotifCard = ({ isRead, notif, handleCloseSideBar }) => (
  <>
    <BsHeartFill color={'#ea442b'} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.liker._id}`}>
        <img
          alt={`${notif.data.liker.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.liker.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.liker._id}`}
        >
          {notif.data.liker.username}
        </StyledLink>{' '}
        liked your reply{' '}
        <p className="line-clamp-2 truncate overflow-hidden">
          "{notif.data.replyText}"
        </p>
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)

//// b3d el PFE
export const ReplyToMyReplyNotifCard = ({
  isRead,
  notif,
  handleCloseSideBar,
}) => (
  <>
    <BsHeartFill color={'#ea442b'} size={25} />

    <NotifCard read={isRead}>
      <Link onClick={handleCloseSideBar} to={`/user/${notif.data.liker._id}`}>
        <img
          alt={`${notif.data.liker.username} profile pic`}
          className="h-10 w-10 object-cover rounded-full"
          src={notif.data.liker.profilePic}
        />
      </Link>

      <label>
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/user/${notif.data.liker._id}`}
        >
          {notif.data.liker.username}
        </StyledLink>{' '}
        liked your list{' '}
        <StyledLink
          onClick={handleCloseSideBar}
          to={`/list/${notif.data.listId}`}
        >
          {notif.data.listName}
        </StyledLink>
      </label>
      <h5 className="text-sm text-slate-400">{formatDate(notif.createdAt)}.</h5>
    </NotifCard>
  </>
)
export const UnlockedBadge = ({ isRead, notif, handleCloseSideBar }) => {
  const badge = badges.find((badge) => badge.name === notif.data.badge)

  return (
    <>
      <img
        src={badge.imageEN}
        className="    h-20"
        style={{ margin: 0, padding: 0 }}
      />

      <NotifCard read={isRead} style={{ marginLeft: -5, minWidth: 200 }}>
        <label>You have unlocked a new badge.</label>
        <h5 className="text-sm text-slate-400">
          {formatDate(notif.createdAt)}.
        </h5>
      </NotifCard>
    </>
  )
}
