import React, { useState } from 'react'
import VerificationInput from 'react-verification-input'
import colors from '../../../utils/style/colors'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function ChangePasswordTab() {
  const [showVerification, setShowVerification] = useState(false)
  const [verificationCode, setVerificationCode] = useState(0)
  const [isVerified, setIsVerified] = useState(false)
  const [password, setPassword] = useState('')

  const [hashedCode, setHashedCode] = useState('')
  const [token, setToken] = useState('')

  const handleSendVerifCode = () => {
    axios
      .get(
        `${process.env.REACT_APP_IP_ADRESS}/api/user/code/getcode`,
        // { text: textValue },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.message === 'ok') {
          setHashedCode(response.data.code)
          toast.success('Code sent successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
          setShowVerification(true)
        } else {
          toast.error('error occurred', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        }
      })
      .catch((error) => {
        console.error('Error sending email :', error)
      })
  }

  const handleVerifyCode = () => {
    axios
      .post(
        `${process.env.REACT_APP_IP_ADRESS}/api/user/code/verifyCode`,
        { verificationCode: verificationCode, hashedCode: hashedCode },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.message === 'Verification code is valid') {
          setToken(response.data.token)
          toast.success('Code is Valid', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
          setIsVerified(true)
        } else {
          toast.error('invalid code ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        }
      })
      .catch((error) => {
        console.error('Error sending email :', error)
      })
  }

  const handleChangePassword = () => {
    axios
      .post(
        `${process.env.REACT_APP_IP_ADRESS}/api/user/password/reset`,
        { password: password, token: token },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.message === 'Password updated successfully') {
          toast.success('Password updated successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
        } else {
          toast.error('session expired', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark',
          })
          setShowVerification(false)
        }
      })
      .catch((error) => {
        console.error('Error sending email :', error)
      })
  }

  const handleVerificationChange = (value) => {
    const parsedValue = parseInt(value, 10)
    setVerificationCode(isNaN(parsedValue) ? 0 : parsedValue)
  }

  return (
    <div>
      <div>
        {isVerified ? (
          <>
            <div className="flex flex-col justify-start items-start">
              <h1 className="text-3xl m-4 mb-0">Enter your new password </h1>
              <div className="flex flex-row w-full mt-5 ">
                <input
                  type="text"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  className="p-2 h-10 flex-1 bg-white/40 border-transparent rounded-full text-black font-bold  "
                  placeholder="reply to this feedback"
                />
                <button
                  onClick={handleChangePassword}
                  className="p-2 text-black font-bold rounded-full border-transparent ml-1 cursor-pointer relative overflow-hidden"
                  style={{
                    backgroundColor: colors.primary,
                    transition: 'background-color 0.3s',
                    color: colors.backgroundDerivation,
                  }}
                  // onClick={sendResponse}
                >
                  send
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {!showVerification ? (
              <button
                style={{
                  backgroundColor: colors.primary,
                  color: colors.backgroundDerivation,
                }}
                className="rounded-full pl-2 pr-2 border-transparent mt-4  font-bold text-base cursor-pointer"
                onClick={handleSendVerifCode}
              >
                Click to Verify
              </button>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-start items-start">
                  <h1 className="text-3xl m-4 mb-0">Verification Code</h1>
                  <p className="m-4">
                    we sent you a verification code, check your Email and enter
                    it on blow.
                  </p>
                </div>

                <VerificationInput
                  className="container"
                  // value={verificationCode.toString()}
                  onChange={handleVerificationChange}
                  characterClassName="character"
                  characterInactiveClassName="character--inactive"
                  characterSelectedClassName="character--selected"
                  validChars="0123456789"
                  length={5}
                />
                <button
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.backgroundDerivation,
                  }}
                  className="rounded-full pl-2 pr-2 border-transparent mt-4  font-bold text-base cursor-pointer"
                  onClick={handleVerifyCode}
                >
                  Verify
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
