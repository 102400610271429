import { t } from 'i18next'
import colors from '../../utils/style/colors'
import { useSSR, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LoginData } from '../../Helper/Context'
import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProfileTab from '../../components/SettingsTabs/ProfileTabs/ProfileTab'
import LinkedAccountsTab from '../../components/SettingsTabs/LinkedAccountsTab/LinkedAccountsTab'
import NotificationsTab from '../../components/SettingsTabs/NotificationsTab/NotificationsTab'
import ChangePasswordTab from '../../components/SettingsTabs/ChangePasswordTab/ChangePasswordTab'
import DeleteAccountTab from '../../components/SettingsTabs/DeleteAccountTab/DeleteAccountTab'
import PendingGamesTab from '../../components/SettingsTabs/PendingGamesTab/PendingGamesTab'

import { Loader } from '../../utils/Atoms'
import LoadingBar from 'react-top-loading-bar'
import Feedbacks from '../../components/SettingsTabs/FeedBacks/Feedbacks'

const SettingsWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${colors.backgroundDerivation};
`

const CoverImage = styled.div`
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px;
  }
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 33, 38, 0)),
      to(${colors.backgroundDerivation})
    );

    background-image: linear-gradient(
      -180deg,
      rgba(0, 33, 38, 0),
      ${colors.backgroundDerivation}
    );
  }

  &:after {
    background: radial-gradient(
      circle,
      rgba(0, 33, 38, 0) 0,
      ${colors.backgroundDerivation} 100%
    );
  }
`
const SettingsTabs = styled.div`
  border-right: 1px solid rgba(217, 217, 217, 0.22);
  background-image: linear-gradient(
    280deg,
    rgba(0, 33, 38, 0),
    ${colors.backgroundDerivation}
  );
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  transition: 2s;
`

const TitleSettings = styled.h1`
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 0;
  padding: 0 1.5rem 1.25rem;
`

export const StyledTab = styled(Link)`
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.6rem 1.375rem;
  display: block;
  transition: all 0.2s ease;
  word-break: break-word;
  position: relative;

  &:hover {
    color: ${colors.primary};
  }
  ${(p) =>
    p.active
      ? `&:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 3px 3px 0;
    background: ${colors.primary};
    z-index: 1;
  }
  background: rgba(217, 217, 217, 0.22);
  font-weight: 700;
  color:${colors.primary};
}
  `
      : ''}
`

export default function Settings({ setShowFooter }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { loginData, setLoginData } = useContext(LoginData)
  const [activeTab, setActiveTab] = useState('profile')
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setShowFooter(false) // Met à jour la variable showFooter pour masquer le footer
    return () => {
      setShowFooter(true) // Rétablit la valeur de showFooter lorsqu'on quitte la page "Settings"
    }
  }, [setShowFooter])

  useEffect(() => {
    if (!loginData) navigate('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const currentTab = urlParams.get('tab')
    if (currentTab) {
      setActiveTab(currentTab)
    } else {
      setActiveTab('profile')
    }
  }, [location])
  if (!loginData) return <></>
  return (
    <>
      <LoadingBar
        color={colors.primary}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <SettingsWrapper className="relative" style={{ zIndex: 2 }}>
        <CoverImage
          style={{
            zIndex: -1,
            backgroundImage: `url(${loginData.coverPic})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
          }}
        />
        <div className="flex flex-1  w-full justify-between items-center flex-row flex-wrap ">
          <SettingsTabs>
            <TitleSettings>{t('settings')}</TitleSettings>
            <StyledTab
              active={activeTab === 'profile' ? 1 : 0}
              to="/settings?tab=profile"
            >
              Profile
            </StyledTab>
            <StyledTab
              to="/settings?tab=linked_accounts"
              active={activeTab === 'linked_accounts' ? 1 : 0}
            >
              Linked accounts
            </StyledTab>
            <StyledTab
              to="/settings?tab=pending_games"
              active={activeTab === 'pending_games' ? 1 : 0}
            >
              Pending Games
            </StyledTab>
            <StyledTab
              to="/settings?tab=notifications"
              active={activeTab === 'notifications' ? 1 : 0}
            >
              Notifications
            </StyledTab>
            <StyledTab
              to="/settings?tab=change_password"
              active={activeTab === 'change_password' ? 1 : 0}
            >
              Change password
            </StyledTab>
            <StyledTab
              to="/settings?tab=delete_account"
              active={activeTab === 'delete_account' ? 1 : 0}
            >
              Delete account
            </StyledTab>
            <StyledTab
              to="/settings?tab=feedback"
              active={activeTab === 'feedback' ? 1 : 0}
            >
              Feedback
            </StyledTab>
          </SettingsTabs>
          <div
            className="flex-1 flex w-full justify-center items-center "
            style={{ paddingTop: '80px' }}
          >
            {loginData && (
              <>
                {activeTab === 'profile' && <ProfileTab />}
                {activeTab === 'linked_accounts' && (
                  <LinkedAccountsTab setProgress={setProgress} />
                )}
                {activeTab === 'notifications' && <NotificationsTab />}
                {activeTab === 'change_password' && <ChangePasswordTab />}
                {activeTab === 'delete_account' && <DeleteAccountTab />}
                {activeTab === 'pending_games' && <PendingGamesTab />}
                {activeTab === 'feedback' && <Feedbacks />}
              </>
            )}
          </div>
        </div>
      </SettingsWrapper>
    </>
  )
}
