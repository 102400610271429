import React, { useContext, useEffect, useState, useRef } from 'react'
import { IoNotificationsOutline } from 'react-icons/io5'

import NotificationIcon from '../../assets/icons/navbar/notification.png'
import NotificationSound from '../../assets/sound/notification.mp3'
import { LoginData } from '../../Helper/Context'
import { getSocket } from '../../socket'

export default function NotificationButton({
  setShowNotifications,
  isSocketConnected,
}) {
  const { loginData, setLoginData } = useContext(LoginData)
  const [notifications, setNotifications] = useState([])
  const notificationSoundRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [originalTitle, setOriginalTitle] = useState('')
  const [isSoundEnabled, setIsSoundEnabled] = useState(true)

  useEffect(() => {
    setOriginalTitle(document.title)
    // Récupérer la valeur actuelle du son des notifications depuis le localStorage
    const soundEnabled = localStorage.getItem('soundEnabled')
    if (soundEnabled === false) setIsSoundEnabled(false)
  }, [])

  useEffect(() => {
    if (loginData) {
      setNotifications(
        loginData.notifications
          ? [...loginData.notifications].filter((notif) => !notif.read)
          : []
      )
    }
  }, [loginData])

  useEffect(() => {
    if (isSocketConnected) {
      const socket = getSocket() // Obtenir le socket

      socket.on('notification', function (notif) {
        console.log(notif)
        setNotifications((prevNotif) => [...prevNotif, notif])

        setLoginData((prevLoginData) => {
          const followingRequests = prevLoginData.followingRequests || []
          const updatedFollowingRequests =
            notif.type === 'accepted_follow'
              ? followingRequests.filter((id) => id !== notif.data.user._id)
              : followingRequests

          return {
            ...prevLoginData,
            notifications: prevLoginData.notifications
              ? [...prevLoginData.notifications, notif]
              : [notif],
            following:
              notif.type === 'accepted_follow'
                ? prevLoginData.following
                  ? [...prevLoginData.following, notif.data.user._id]
                  : [notif.data.user._id]
                : prevLoginData.following,
            followingRequests: updatedFollowingRequests,
          }
        })

        // Jouer le son de notification
        if (!isPlaying && notificationSoundRef.current && isSoundEnabled) {
          notificationSoundRef.current.play()
          setIsPlaying(true)
        }
      })

      // Nettoyage du socket à la fin
      return () => {
        socket.disconnect()
      }
    }
  }, [isSocketConnected])

  useEffect(() => {
    if (notifications.length > 0) {
      document.title = `(${notifications.length}) ${originalTitle}`
    } else {
      document.title = originalTitle
    }
  }, [notifications, originalTitle])

  const handleSoundEnded = () => {
    setIsPlaying(false)
  }

  return (
    <>
      <audio
        ref={notificationSoundRef}
        id="notification-sound"
        onEnded={handleSoundEnded}
      >
        <source src={NotificationSound} type="audio/mpeg" />
      </audio>
      <div
        className="notification cursor-pointer relative"
        onClick={() => setShowNotifications(true)}
      >
        {notifications.length > 0 && (
          <span className="p-1 px-2 border-none bg-red-500 rounded-full text-white font-extrabold text-sm absolute -top-1 -right-2">
            {notifications.length}
          </span>
        )}
        <img src={NotificationIcon} className="icon" alt="Notification-icon" />
      </div>
    </>
  )
}
