import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'

import UserCard from '../../components/UserCard/UserCard'
import ErrorOccured from '../../components/ErrorOccured/ErrorOccured'
import UserCardPlaceHolder from '../../components/UserCard/UserCardPlaceHolder'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Avatar, ConfigProvider, Divider, List, Skeleton } from 'antd'
import colors from '../../utils/style/colors'

const Wrapper = styled.div`
  height: 120vh;
`

const SearchUser = ({ setSearchValue, setSearchType }) => {
  const location = useLocation()

  const { t } = useTranslation()
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(1)
  const [totalUsers, setTotalUsers] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [querySearch, setQuerySearch] = useState(null)

  const loadMoreData = () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    console.log('querySearch = ' + querySearch)
    axios
      .get(
        `${process.env.REACT_APP_IP_ADRESS}/api/user/search?username=${querySearch}&page=${page}`
      )
      .then((res) => {
        setUsers([...users, ...res.data.users])
        console.log(res.data)
        setTotalUsers(res.data.totalUsers)
        setPage((prevPage) => prevPage + 1)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    // params ml link
    const searchParams = new URLSearchParams(location.search)
    const q = searchParams.get('q')
    if (q) {
      console.log(q)
      setSearchType('user')
      setQuerySearch(q)
    } else {
      setQuerySearch('')
    }
    document.title = 'Search users - My PlayLog'
  }, [])

  useEffect(() => {
    if (querySearch !== null) loadMoreData()
  }, [querySearch])

  return (
    <Wrapper className="w-full">
      <div className=" w-full flex flex-col justify-center items-center ">
        {error !== null && <ErrorOccured />}

        <div
          className="  w-full px-4 overflow-y-scroll scrollbar-thumb-yellow-500 scrollbar-thin scrollbar-rounded-[50px]"
          id="scrollableDiv"
          style={{
            paddingTop: 80,
            height: '120vh',
          }}
        >
          <h3>
            {' '}
            <strong style={{ color: colors.primary }}>{totalUsers}</strong>{' '}
            résultats.
          </h3>

          <InfiniteScroll
            dataLength={users.length}
            next={loadMoreData}
            hasMore={users.length < totalUsers}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
            endMessage={
              <Divider plain>
                {totalUsers > 15 && (
                  <h3 className="text-white">No more users 🌍</h3>
                )}
              </Divider>
            }
            scrollableTarget="scrollableDiv"
          >
            <ConfigProvider
              theme={{
                token: {
                  fontWeightStrong: 800,
                  colorText: 'white',
                  fontFamily: 'Inter',
                },
              }}
            >
              <List
                className="sm:p-0 md:p-6"
                loading={isLoading}
                dataSource={users}
                grid={{
                  gutter: 16,

                  sm: 1,
                  md: 2,
                  xl: 3,
                  xxl: 4,
                }}
                style={{
                  color: 'white',
                  overflow: 'hidden',
                }}
                renderItem={(item) => (
                  <UserCard ele={item} key={`user_${item._id}`} />
                )}
              />
            </ConfigProvider>
          </InfiniteScroll>
        </div>
      </div>
    </Wrapper>
  )
}

export default SearchUser
