import collector_EN from '../../assets/images/Badges/collector_badge_EN.png'
import collector_FR from '../../assets/images/Badges/collector_badge_FR.png'
import feedback_EN from '../../assets/images/Badges/feedback_badge_EN.png'
import feedback_FR from '../../assets/images/Badges/feedback_badge_FR.png'
import liker_EN from '../../assets/images/Badges/liker_badge_EN.png'
import liker_FR from '../../assets/images/Badges/liker_badge_FR.png'
import review_EN from '../../assets/images/Badges/review_badge_EN.png'
import review_FR from '../../assets/images/Badges/review_badge_FR.png'
import social_EN from '../../assets/images/Badges/social_badge_EN.png'
import social_FR from '../../assets/images/Badges/social_badge_FR.png'

const badges = [
  {
    name: 'collector',
    imageEN: collector_EN,
    imageFR: collector_FR,
    descriptionEN: 'Unlock this badge by adding 50 games.',
    descriptionFR: 'Débloquez ce badge en ajoutant 50 jeux.',
  },
  {
    name: 'feedback',
    imageEN: feedback_EN,
    imageFR: feedback_FR,
    descriptionEN: 'Unlock this badge by giving feedback about the app.',
    descriptionFR:
      "Débloquez ce badge en donnant un feedback sur l'application.",
  },
  {
    name: 'liker',
    imageEN: liker_EN,
    imageFR: liker_FR,
    descriptionEN: 'Unlock this badge by getting 20 likes on lists or reviews.',
    descriptionFR:
      'Débloquez ce badge en obtenant 20 likes sur des listes ou des avis.',
  },
  {
    name: 'review',
    imageEN: review_EN,
    imageFR: review_FR,
    descriptionEN: 'Unlock this badge by posting 20 reviews.',
    descriptionFR: 'Débloquez ce badge en publiant 20 avis.',
  },
  {
    name: 'social',
    imageEN: social_EN,
    imageFR: social_FR,
    descriptionEN: 'Unlock this badge by following 20 users.',
    descriptionFR: 'Débloquez ce badge en suivant 20 utilisateurs.',
  },
]

export default badges
