import { io } from 'socket.io-client'

const URL = process.env.REACT_APP_IP_ADRESS

let socket = null
let token = localStorage.getItem('token')

export const initializeSocket = (loginData) => {
  if (loginData) {
    socket = io(URL, {
      auth: {
        loginData,
      },
    })
  }
}

export const getSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized. Call 'initializeSocket' first.")
  }

  return socket
}

export const disconnectSocket = () => {
  if (socket) socket.disconnect()
}
